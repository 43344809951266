.notfound {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.5rem;
  padding-top: 1.5rem;
  line-height: 1.75;
}
