.background-div {
  background: url("../imgs/hero-svg-transparent.svg");
  background-size: cover;
  background-position: center;

  height: 110vh;
  min-height: 45rem;
  width: 100%;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10vh;
}

.box-layout {
  display: flex;
  margin: 3rem 0;
  height: 30rem;
  justify-content: center;
  max-width: 100vw;
}
