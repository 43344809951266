// Colors
$main-red: #991111;
$dark-purple: #154890;
$bright-purple: #6699ff;
$main-grey: #d0d0d0;
$black: #1d1d1d;

// Spacing
$s-size: 0.6rem;
$m-size: 0.8rem;
$l-size: 1.6rem;
$xl-size: 2.4rem;
