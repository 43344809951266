.about-me-layout {
  background: linear-gradient(
      rgba($dark-purple, 0.85),
      rgba($dark-purple, 0.85)
    ),
    url(../imgs/aboutBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: -10vh;
  margin-bottom: -10vh;
  padding: 10vh 0;
  clip-path: polygon(0 10vh, 100% 0, 100% calc(100% - 10vh), 0 100%);

  @include atDesktop {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;

    @supports (-ms-ime-align: auto) {
      /* Fallback for Edge only */
      justify-content: space-around;
    }
  }
}

.pool-container {
  padding: 5rem $s-size;
  padding-bottom: 2.5rem;

  @include atDesktop {
    padding-bottom: 5rem;
  }
}

.pool {
  background: $bright-purple;
  border: 0.75rem solid $main-grey;
  border-radius: 1rem;
  color: $black;
  width: 17.5rem;
  @include flexCenterBoth;
  flex-direction: column;
  box-shadow: 0.75rem -0.75rem 0.5rem 0.25rem rgba(0, 0, 0, 0.1);

  &_header {
    padding: $s-size;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 1;
    border-bottom: 1px solid currentColor;
    margin-bottom: $s-size;
    color: inherit;
  }

  &_text {
    padding: $s-size $m-size;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.05rem;
    width: 100%;
    color: inherit;

    &--quote {
      text-align: center;

      & em {
        font-size: 1.25em;
        color: inherit;
      }
    }
  }
}

.about-me__table-container {
  background: $main-grey;
  border-radius: 2rem;
  width: 17.5rem;
  @include flexCenterBoth;
  flex-direction: column;
  box-shadow: 0.75rem -0.75rem 0.5rem 0.25rem rgba(0, 0, 0, 0.15);

  h2 {
    text-align: center;
    font-size: 1.7rem;
    padding: $s-size;
    padding-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0;
    color: $black;
    border-bottom: 1px solid currentColor;
    margin-bottom: -0.5rem;
  }
}

.about-me__chair-table-container {
  padding: 5rem 0.6rem;
  padding-top: 2.5rem;
  @include flexCenterBoth;
  flex-direction: column;

  @include atDesktop {
    padding-top: 5rem;
  }
}

.about-me__chairs-container {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 17.5rem;
}

.about-me__chair {
  background: linear-gradient(
    to bottom,
    $bright-purple,
    $bright-purple 20%,
    $main-grey 20%,
    $main-grey
  );
  width: 4rem;
  height: 4rem;
  box-shadow: 0.3rem -0.3rem 0.25rem 0.1rem rgba(0, 0, 0, 0.2);

  &--bottom {
    background: linear-gradient(
      to top,
      $bright-purple,
      $bright-purple 20%,
      $main-grey 20%,
      $main-grey
    );
  }
}

.tablet-container {
  font-size: 2px;
  box-sizing: content-box;

  @include atPhoneLg {
    font-size: 3px;
  }
}

.tablet {
  box-sizing: content-box;
  position: relative;
  width: 51.2em;
  height: 68.2667em;
  margin: auto;
  border: 1em $black solid;
  border-top-width: 4em;
  border-bottom-width: 4em;
  border-radius: 2.4em;
  @include flexCenterBoth;
  transform: rotate(90deg);
}

/* The horizontal line on the top of the device */
.tablet:before {
  content: "";
  display: block;
  width: 4em;
  height: 0.3333em;
  position: absolute;
  top: -2em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 0.666667em;
}

/* The circle on the bottom of the device */
.tablet:after {
  content: "";
  display: block;
  width: 2.3333em;
  height: 2.3333em;
  position: absolute;
  left: 50%;
  bottom: -4.3333em;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.tablet-content {
  width: 68.2667em;
  height: 41em;
  border: none;
  transform: rotate(-90deg);
}
