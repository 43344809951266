.contact-section {
  min-width: 260px;
  width: 100%;
  min-height: 47rem;
  clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
  padding: 3rem 1rem;
  padding-top: calc(10vh + 3rem);
  margin-top: -10vh;
  @include flexCenterBoth;
  flex-direction: column;
  background: linear-gradient(rgba($dark-purple, 0.9), rgba($dark-purple, 0.9)),
    url(../imgs/lets-connect-no-lines.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-form-container {
  width: 100%;
  max-width: 600px;
}

.contact-error {
  color: $main-red;
}

.contact-form {
  width: 90%;
  height: 31.5rem;
  margin: 0 auto;
  font-family: "Indie Flower", cursive;
  border: 0.25rem solid $black;
  color: $dark-purple;
  padding: $m-size;
  border-radius: 0.25rem 0.25rem 0 0;
  background: $main-grey;

  @include atDesktop {
    height: 30rem;
  }

  &-form {
    width: 100%;
  }

  &-whiteboard-bottom {
    width: 90%;
    height: 1.5rem;
    border: 0.25rem solid $black;
    border-top: none;
    transform: skewX(-45deg) translate(-0.75rem, 0);
    margin: 0 auto;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 1rem 0.5rem 1rem 0.25rem rgba(0, 0, 0, 0.2);
    background: $main-grey;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-title {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: $m-size;
  }

  &-label {
    align-self: flex-start;
    color: inherit;
    letter-spacing: 1px;
  }

  &-input-div {
    width: 100%;
    display: flex;

    &:before {
      content: "X";
      color: $bright-purple;
      font-size: $l-size;
      padding-bottom: 0.25rem;
      line-height: 1;
    }
  }

  &-contactinput {
    @include inputBase;
    border-bottom: 1px solid currentColor;
    margin-bottom: $m-size;
    line-height: 1;
    font-size: $m-size;
    padding-left: 0.5rem;
    background: transparent;
    color: inherit;
  }

  @mixin commentsBg($fs, $lh) {
    background: repeating-linear-gradient(
      $main-grey,
      $main-grey $lh - 2px,
      $dark-purple $lh - 2px,
      $dark-purple $lh
    );
    font-size: $fs;
    line-height: $lh;
    padding: $lh - $fs 0 0 0;
    height: $lh * 10;
  }

  &-comments-area {
    @include commentsBg(13px, 15px);
    @include inputBase;
    resize: none;
    overflow: auto;
    margin-bottom: 0.8rem;

    @include atPhoneLg {
      @include commentsBg(16px, 19px);
    }

    @include atTablet {
      @include commentsBg(19px, 24px);
    }
  }

  &-label-error-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-error {
    color: red;
    font-size: 0.7rem;
  }

  &-submit {
    border: none;
    color: inherit;
    background: transparent;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    transition: transform 0.3s;

    &:hover {
      color: $bright-purple;
    }

    .email-icon {
      font-size: 1.8rem;
      color: inherit;
    }
  }
}

.fade-away {
  animation: fade-away 1s forwards;
}

.hide-form {
  display: none;
}

@keyframes fade-away {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.marker {
  display: flex;
  align-items: center;
  transform: skewX(10deg);
}

.marker-body {
  width: 2.5rem;
  height: 0.25rem;
  background: $black;
  transform: translateY(-0.2rem);
  box-shadow: #111111 1px 1px, #111111 2px 2px, #111111 3px 3px, #111111 4px 4px,
    #111111 5px 5px;
  border-radius: 0.25rem 0 0 0.25rem;
}

.marker-cap {
  width: 1rem;
  height: 0.35rem;
  background: $dark-purple;
  transform: translateY(-0.2rem);
  box-shadow: lighten($dark-purple, 5%) 1px 1px,
    lighten($dark-purple, 5%) 2px 2px, lighten($dark-purple, 5%) 3px 3px,
    lighten($dark-purple, 5%) 4px 4px, lighten($dark-purple, 5%) 5px 5px;
  border-radius: 0 0.25rem 0.25rem 0;
}

.eraser {
  width: 3.5rem;
  height: 1rem;
  line-height: 1.5;
  font-size: $s-size;
  padding-left: 1rem;
  color: darken($main-grey, 10%);
  background: $black;
  transform: translateY(-0.5rem) skewX(10deg);
  position: relative;
  box-shadow: $black 1px 1px, $black 2px 2px, $black 3px 3px, $black 4px 4px,
    $black 5px 5px, $black 6px 6px, currentColor 7px 7px, currentColor 8px 8px,
    currentColor 9px 9px;
  border-radius: 0.25rem;
}
