// Base media queries
@mixin mediaQuery($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin heightMediaQuery($height) {
  @media only screen and (min-height: $height) {
    @content;
  }
}

// Screen size media queries
@mixin atDesktop {
  @include mediaQuery("1000px") {
    @content;
  }
}

@mixin atTablet {
  @include mediaQuery("450px") {
    @content;
  }
}

@mixin atPhoneLg {
  @include mediaQuery("350px") {
    @content;
  }
}

// Individual section media queries
@mixin atProjectBreakpoint {
  @include mediaQuery("540px") {
    @content;
  }
}

@mixin atLgProjectBreakpoint {
  @include mediaQuery("670px") {
    @content;
  }
}

@mixin atProjectMinHeight {
  @include heightMediaQuery("40rem") {
    @content;
  }
}

// Layout Mixins: Flex
@mixin flexCenterBoth {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Input Mixins
@mixin inputBase {
  width: 100%;
  letter-spacing: 1px;
  border: none;
  font-family: inherit;
}
