* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: $dark-purple;
}

html {
  scroll-behavior: smooth;
  font-size: 80%;

  @include atPhoneLg {
    font-size: 100%;
  }

  @include atTablet {
    font-size: 125%;
  }

  @include atDesktop {
    font-size: 150%;
  }
}

body {
  color: #111;
  font-family: "Lato", sans-serif;
  font-size: $m-size;
  line-height: 1.6;
  background: $bright-purple;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

a:visited {
  color: inherit;
}

.is-active {
  font-weight: bold;
}
