.paper-box {
  font-family: "Indie Flower", cursive;
  width: 17.5rem;
  height: 30rem;
  background: url("../imgs/notebook-page-transparent.jpg");
  background-size: cover;
  border-radius: 5px;
  animation: fadeIn 1s ease-out;
  align-self: center;
  position: relative;
  padding-left: 3rem;
}

.paper-qa {
  height: 22.5rem;
}

.paper-tape-container {
  margin-top: -2rem;
  display: flex;
  justify-content: space-between;
}

.paper-tape {
  width: 1.7rem;
  height: 3.3rem;
  background-size: contain;
  &--left {
    animation: moveInTopLeft 2s ease-out;
    margin-left: -3rem;
    background-image: url("../imgs/tape-left-cropped-rot.png");
  }

  &--right {
    animation: moveInTopRight 2s ease-out;
    background-image: url("../imgs/tape-right-cropped-rot.png");
  }
}

.paper-header {
  margin: -0.6rem 0 0.4rem 0;
  text-align: center;
  line-height: 1;
  font-size: 1.4rem;
  color: $bright-purple;

  span {
    font-size: 1.2rem;
    color: inherit;
  }
}

.paper-text {
  padding-top: 0.25rem;
  margin: 0.35rem 0.25rem 0 0.2rem;
  font-size: 0.9rem;
  line-height: 0.95;

  &-mtop {
    margin-top: 0.5rem;
  }

  &--center {
    margin-left: 0;
    text-align: center;
    display: block;
    text-transform: capitalize;
    font-size: 1.2rem;
  }

  &--chrome {
    line-height: 0.9;
  }

  &-ql {
    margin-top: 0;
    padding-top: 0.4rem;
  }

  &-answer {
    margin-top: 0.25rem;
    color: $bright-purple;
  }

  &-area {
    background: transparent;
    border: none;
    font-family: inherit;
    height: 5.6rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    color: $bright-purple;
    padding: 0.2rem;
    margin-left: 0;

    &::placeholder {
      color: $main-grey;
      text-align: center;
    }
  }
}

.paper-ask {
  margin: 0 auto;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid currentColor;
  font-family: inherit;
  color: inherit;
  font-size: 1.1rem;
}

.paper-contact-link-div {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
}

.paper-contact-link {
  text-decoration: none;
  font-size: 1rem;
  transition: transform 0.7s linear;

  &:hover {
    cursor: pointer;
    animation: blink 0.7s linear infinite;
  }
}

.paper-contact-link-a {
  display: flex;
  align-items: flex-end;
  text-decoration: none;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes moveInTopLeft {
  0% {
    opacity: 0;
    transform: translate(-100%, -100%) rotate(90deg);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTopRight {
  0% {
    opacity: 0;
    transform: translate(100%, -100%) rotate(-90deg);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
